<template>
  <div>
    <ViewTemplateImportFile :panel="panel" :templateList="templateList" container="nixloc-product-import"
      :typeImport="typeImport" :select="options()" urlMapping="/api/v1/stock/import-product/mapping"
      urlValidate="/api/v1/stock/import-product/validate" urlImport="/api/v1/stock/import-product/import"
      urlTemplate="https://espaco.blob.core.windows.net/nixloc-product-import/Modelo Importação Produto.xlsx">
      <div>
        <RadioGroup title="O que será importado?" field="typeImport" :options="[
          { text: 'Equipamento, Acessório ou Serviço', value: 1 },
          { text: 'Patrimônio(s)', value: 2 },
          { text: 'Tabela de Preço', value: 3 },
          { text: 'Acessório(s) Vinculado(s)', value: 4 },
        ]" v-model="typeImport" />
      </div>
    </ViewTemplateImportFile>
  </div>
</template>

<script>
import ViewTemplateImportFile from "@nixweb/nixloc-ui/src/component/template/ViewTemplateImportFile";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import { mapMutations } from "vuex";

export default {
  name: "ImportProductView",
  components: { ViewTemplateImportFile, RadioGroup },
  data() {
    return {
      panel: {
        module: "Estoque",
        title: "Importar",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },

      typeImport: 1,
      selectProduct: [
        { content: "Código Interno", id: "codInternal" },
        { content: "Equipamento, Acessório ou Serviço", id: "isTypeProduct" },
        { content: "Url Foto", id: "urlPhoto" },
        { content: "Tipo", id: "type" },
        { content: "Nome", id: "name" },
        { content: "Categoria", id: "category" },
        { content: "Fabricante", id: "manufacturer" },
        { content: "Descrição", id: "description" },
        { content: "Código por Grupo", id: "barCode" },
        { content: "Data da Compra", id: "purchaseDate" },
        { content: "Compra/Reposição", id: "replacementValue" },
        { content: "Unidade de Medida", id: "unitOfMeasurement" },
        { content: "Estoque", id: "totalStock" },
        { content: "Seguro %", id: "percentageInsurance" },
        { content: "Seguro Locação/Reposição", id: "calculateInsurance" },
        { content: "Ncm", id: "ncm" },
      ],
      selectPatrimony: [
        { content: "Código Interno", id: "codInternal" },
        { content: "Nº Patrimônio", id: "barCode" },
        { content: "Nº Série", id: "serialNumber" },
        { content: "Data da Compra", id: "purchaseDate" },
      ],
      selectTablePrice: [
        { content: "Código Interno", id: "codInternal" },
        { content: "Tipo", id: "typePrice" },
        { content: "Período", id: "quantity" },
        { content: "Valor", id: "value" },
        { content: "Custo", id: "cost" },
      ],
      selectAccessory: [
        { content: "Código Interno Produto", id: "codInternalProduct" },
        { content: "Código Interno Acessório", id: "codInternalAccessory" },
        { content: "Quantidade", id: "quantity" },
      ],
      templateList: {
        urlGetApi: "/api/v1/stock/history-import/get-all",
        urlDeleteAllApi: "/api/v1/stock/history-import/delete",
        showChecks: false,
        headerTable: [
          {
            field: "description",
            title: "Descrição",
            type: "text",
          },
          {
            field: "registeredDate",
            title: "Data",
            type: "dateTime",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "registeredUser",
            title: "Usuário",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "totalImported",
            title: "Total Importado",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            type: "button",
            typeButton: "default",
            button: {
              classIcon: "fa-solid fa-trash",
              type: "danger",
              size: "small",
              eventName: "deleteHistoryImport",
            },
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
      },
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    options() {
      if (this.typeImport == 1) return this.selectProduct;
      if (this.typeImport == 2) return this.selectPatrimony;
      if (this.typeImport == 3) return this.selectTablePrice;
      if (this.typeImport == 4) return this.selectAccessory;
    },
  },
};
</script>
